import {
	memo,
	Suspense,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { Link, useLocation } from "react-router-dom";
import { isEqual } from "lodash";
import { disableScroll, enableScroll } from "utils/stopScrolling";
import {
	headerItemData,
	homepageLogo,
	homepageLogoSrc,
	homepageMenuIconMobile,
	homepageLogoWhiteSrc,
	homepage,
	homepageMenuIconWhiteMobile,
	headerLogoutSrc,
	headerLogoutSrcForMobile,
} from "config/defaultData";
import {
	BookingCancel,
	BookingFailed,
	BookingInvalid,
	BookingSuccess,
	EmailsentFailed,
	EmailsentSuccessfully,
	EmailVerifyFailed,
	EmailVerifySuccessfully,
	Franchising,
	Ideal,
	MaterialsCoating,
	MaterialsPPF,
	PartnershipRegiseredSuccessfully,
	Pathname_ContactUs,
	Preface417,
	ReadmoreGyeon,
	ReadmorePPF,
	RegExp_WorksArticle,
} from "config/pathname";
import {
	LazyAboutMaterials,
	LazyAboutUs,
	LazyBackdrop,
	LazyButtonWithScale,
	LazyLoginPage,
	LazyMobileMenuDetail,
	LazyService,
} from "config/lazyComponent";
import MySpinner from "components/common/MySpinner";
import { userIsLoginRequest, userLogoutRequest } from "config/request";
import { BookingDetail } from "config/className";
import {
	EN,
	Login,
	Path_AboutMaterials,
	Path_AboutUs,
	Path_Service,
	UserPanel,
} from "config/text";
import "assets/fontawesome-free/css/all.css";
import "./index.css";

const data = headerItemData;
const logoTo = homepageLogo;
const colorWhite = "white";
const boxShadow = "0 .25rem .25rem -0.125rem rgba(0,0,0,0.25)";

/**
 * the header
 * optimized except scrolling
 */
function Header() {
	const [path, setPath] = useState(""); // the path passing through to the subpage(aboutus, service, aboutmaterials)
	const [isLogin, setIsLogin] = useState(false); // whether clickling the login btn of the header or not
	const [isClickMobileMenu, setIsClickMobileMenu] = useState(false); // whether clicking the mobile menu icon
	const [onMobile, setOnMobile] = useState(window.innerWidth <= 991.98); // add mobile menu dynamically
	const [userIsLogin, setUserIsLogin] = useState(false); // user is login or not
	const [headerToWhiteFlag, setHeaderToWhiteFlag] = useState(false); // change the header's text to white or not
	const [scrollDistance, setScrollDistance] = useState(0); // scrollY
	const { search, pathname } = useLocation();

	// refs
	const headerRef = useRef();
	const tabsRef = useRef();

	/**
	 * state 'headerToWhiteFlag' Handler
	 * @param {String} pathname window.location.pathname
	 */
	const setHeaderToWhiteFlagByPathname = useCallback((pathname) => {
		switch (pathname) {
			case MaterialsPPF:
				setHeaderToWhiteFlag(true);
				break;
			case MaterialsCoating:
				setHeaderToWhiteFlag(true);
				break;
			case PartnershipRegiseredSuccessfully:
				setHeaderToWhiteFlag(true);
				break;
			case BookingSuccess:
				setHeaderToWhiteFlag(true);
				break;
			case BookingCancel:
				setHeaderToWhiteFlag(true);
				break;
			case BookingFailed:
				setHeaderToWhiteFlag(true);
				break;
			case BookingInvalid:
				setHeaderToWhiteFlag(true);
				break;
			case EmailsentSuccessfully:
				setHeaderToWhiteFlag(true);
				break;
			case EmailsentFailed:
				setHeaderToWhiteFlag(true);
				break;
			case EmailVerifyFailed:
				setHeaderToWhiteFlag(true);
				break;
			case EmailVerifySuccessfully:
				setHeaderToWhiteFlag(true);
				break;
			case ReadmorePPF:
				setHeaderToWhiteFlag(true);
				break;
			case ReadmoreGyeon:
				setHeaderToWhiteFlag(true);
				break;
			case Franchising:
				setHeaderToWhiteFlag(true);
				break;
			case Preface417:
				setHeaderToWhiteFlag(true);
				break;
				// case Ideal:
				//     setHeaderToWhiteFlag(true)
				break;
			default:
				const res = RegExp_WorksArticle.test(pathname);
				console.log(RegExp_WorksArticle.test(pathname)); // not showing at first if removing this line
				if (res) {
					setHeaderToWhiteFlag(true);
				} else {
					setHeaderToWhiteFlag(false);
				}
				break;
		}
	}, []);

	/**
	 * add some styles to the header, white bgc & black boxShadow
	 * @param {Boolean} conditions true no style, false add style, default to false
	 * */
	const headerStyleSetter = useCallback(
		(conditions = false) => {
			let { style } = headerRef.current;
			const { scrollY } = window;
			const { pathname } = window.location;
			if (conditions && isEqual(scrollY, 0)) {
				// no style
				style.backgroundColor = "";
				style.boxShadow = "";
				setHeaderToWhiteFlagByPathname(pathname);
			} else {
				// add style
				style.backgroundColor = colorWhite;
				style.boxShadow = boxShadow;

				setHeaderToWhiteFlagByPathname("");
			}
		},
		[headerRef]
	);

	// 'scroll' event handler
	const scrollEventHandler = useCallback(
		(event) => {
			const { scrollY } = window;
			const { pathname } = window.location;
			const { className, scrollTop } = event.target;

			if (onMobile) {
				// mobile
				switch (className) {
					case BookingDetail:
						headerStyleSetter(isEqual(scrollTop, 0));
						break;

					default:
						headerStyleSetter(isEqual(scrollY, 0));
						break;
				}
			} else {
				// desktop
				switch (pathname) {
					// case ReadmorePPF || ReadmoreGyeon:
					//     headerStyleSetter(isEqual(scrollTop, 0))
					//     break;
					case MaterialsPPF:
						setHeaderToWhiteFlag(false);
						headerStyleSetter(isEqual(scrollY, 0) || isEqual(scrollTop, 0));
						break;

					default:
						headerStyleSetter(isEqual(scrollY, 0));
						break;
				}
			}
		},
		[headerRef]
	);

	// setting the path to ""
	const setEmptyPath = useCallback(() => setPath(""), []);

	// control the box shadow of the header
	const showShadow = useCallback(() => headerStyleSetter(false), []);
	const hideShadow = useCallback(() => headerStyleSetter(true), []);

	// login button Handler
	const isLoginHandler = useCallback(() => setIsLogin(true), []);
	const offLoginHandler = useCallback(() => setIsLogin(false), []);

	// logout button Handler
	const logoutHandler = useCallback(() => {
		userLogoutRequest()
			.then((res) => {
				if (res.data?.status === 0) setUserIsLogin(false);
				window.location.replace(homepage);
			})
			.catch((reason) => console.log(reason))
			.finally(() => {
				setUserIsLogin(false);
				window.location.replace(homepage);
			});
	}, []);

	useEffect(() => {
		window.innerWidth <= 991.98 ? setOnMobile(true) : setOnMobile(false); // mobile or desktop

		// adding a scroll eventListener to document, which shows the boxShadow and bgColor when scrolling
		document.addEventListener("scroll", scrollEventHandler, true);

		// user is login or not
		userIsLoginRequest().then(
			(resp) => {
				const { status } = resp.data;
				if (isEqual(status, 1)) setUserIsLogin(false);
				if (isEqual(status, 0)) setUserIsLogin(true);
			},
			(err) => console.log(err)
		);
	}, []);

	useEffect(() => {
		const { pathname } = window.location;
		headerStyleSetter(!isEqual(pathname, BookingSuccess));
		setHeaderToWhiteFlagByPathname(pathname);
	}, [window.location.pathname]);

	// stop scrolling hold on---add class to solve
	useEffect(() => {
		const root = document.getElementById("root");

		if (isClickMobileMenu) {
			disableScroll(document.querySelector(".backdrop"));
			root.classList.add("stopScrolling");
			root.scrollTo({
				top: Number(sessionStorage.getItem("wy")),
				behavior: "instant",
			});
		} else {
			enableScroll(document.querySelector(".backdrop"));
			root.classList.remove("stopScrolling");
			window.scrollTo({
				top: Number(sessionStorage.getItem("wy")),
				behavior: "instant",
			});
		}
	}, [isClickMobileMenu]);
	// useEffect(() => {
	//     if(isLogin) disableScroll(document.querySelector(".backdrop"))
	//     else enableScroll(document.querySelector(".backdrop"))
	// }, [isLogin])

	const renderSubItem = useCallback(
		(p) => {
			switch (p) {
				case Path_AboutUs:
					return (
						<Suspense fallback={<MySpinner />}>
							<LazyAboutUs path={path} />
						</Suspense>
					);
				case Path_Service:
					return (
						<Suspense fallback={<MySpinner />}>
							<LazyService path={path} />
						</Suspense>
					);

				case Path_AboutMaterials:
					return (
						<Suspense fallback={<MySpinner />}>
							<LazyAboutMaterials path={path} />
						</Suspense>
					);

				default:
					break;
			}
		},
		[path]
	);

	// render header items
	const renderHeaderItems = useCallback(
		() =>
			data.map((item) =>
				!(item.hideAfterLogin && userIsLogin) ? (
					<li
						key={item.id}
						className={item.hasArrow ? "hasArrow" : ""}
						onMouseLeave={setEmptyPath}
						onMouseEnter={
							item.subpageComponentName ? () => setPath(item.path) : () => {}
						}
					>
						{isEqual(item.to, Pathname_ContactUs) ? (
							<a href={item.to}>{item.text}</a>
						) : (
							<Link
								to={
									isEqual(item.disable, true)
										? `${pathname}${search}`
										: `${item.to}${search}`
								}
								className={isEqual(item.disable, true) ? "disable" : ""}
							>
								{item.text === EN && <i className="pipe"></i>}
								{item.text}
								{item.hasArrow && <i className="fa-solid fa-chevron-down"></i>}
							</Link>
						)}

						{/* how to dynamically import component tag name */}
						{
							item.path === "aboutus" ? (
								<LazyAboutUs path={path} />
							) : item.path === "service" ? (
								<LazyService path={path} />
							) : item.path === "aboutmaterials" ? (
								<LazyAboutMaterials path={path} />
							) : null
							// renderSubItem(item.path)
						}
					</li>
				) : (
					<li
						key={item.id}
						onClick={() =>
							window.location.assign(
								"https://" + window.location.host + "/user_panel/income"
							)
						}
					>
						<LazyButtonWithScale text={UserPanel} style={{ width: 128 }} />
					</li>
				)
			),
		[data, path, userIsLogin]
	);

	const resultOfRenderHeaderItems = useMemo(
		() => renderHeaderItems(),
		[data, path, userIsLogin]
	);

	return (
		<>
			<div
				className="header"
				onMouseLeave={window.scrollY === 0 ? hideShadow : () => {}}
				onMouseEnter={showShadow}
				ref={headerRef}
			>
				<a href={logoTo}>
					<img
						src={`${homepageLogoSrc}`}
						alt="missing"
						className="logo"
						style={{ visibility: headerToWhiteFlag ? "hidden" : "visible" }}
					/>
					<img
						src={`${homepageLogoWhiteSrc}`}
						alt="missing"
						className="logo"
						style={{ visibility: headerToWhiteFlag ? "visible" : "hidden" }}
					/>
				</a>

				<div
					className={headerToWhiteFlag ? "tabs allToWhite" : "tabs"}
					ref={tabsRef}
				>
					<ul>{resultOfRenderHeaderItems}</ul>
				</div>

				{onMobile && (
					<div
						className="mobileMenu"
						onClick={() => {
							setScrollDistance(window.scrollY);
							sessionStorage.setItem("wy", window.scrollY);
							setIsClickMobileMenu(true);
						}}
						style={{ display: onMobile ? "flex" : "none" }}
					>
						<span
							className="menuIcon"
							style={{
								backgroundImage: `url(${homepageMenuIconMobile})`,
								visibility: headerToWhiteFlag ? "hidden" : "visible",
							}}
						></span>
						<span
							className="menuIcon"
							style={{
								backgroundImage: `url(${homepageMenuIconWhiteMobile})`,
								visibility: headerToWhiteFlag ? "visible" : "hidden",
							}}
						></span>
					</div>
				)}
			</div>

			{/* pc login btn detail page */}
			<LazyLoginPage
				offLoginHandler={offLoginHandler}
				isClick={isLogin}
				setUserIsLogin={setUserIsLogin}
			/>

			{/* mobile menu btn detail page */}

			<div
				className="mobileMenubar"
				style={{
					position: "relative",
					zIndex: 16,
				}}
			>
				<LazyMobileMenuDetail
					style={{
						position: "fixed",
						top: 0,
						left: 0,
						opacity: isClickMobileMenu ? 1 : 0,
						width: isClickMobileMenu ? "calc( 100vw * 324 / 390)" : 0,
						// height: isClickMobileMenu ? 100 : 0,
					}}
					setIsClickMobileMenu={setIsClickMobileMenu}
					isClickMobileMenu={isClickMobileMenu}
					userIsLogin={userIsLogin}
					setUserIsLogin={setUserIsLogin}
				/>
			</div>

			<LazyBackdrop
				onClick={() => setIsClickMobileMenu(false)}
				style={{ display: isClickMobileMenu ? "block" : "none" }}
			/>
		</>
	);
}

export default memo(Header);
