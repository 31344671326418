import axios from "utils/axios";
import { mrWrapperModelList } from "./MrWrapperAirtableService";

// judge user is login or not
export const userIsLoginRequest = () => axios.get("auth/info");

// user logout
export const userLogoutRequest = () => axios.get("auth/logout");

// create vcode
export const createVCode = (params) =>
	axios.post("/createVerification", params);

// reg partner
export const registerPartner = (params) => axios.post("/reg", params);

// get all steps
export const allSteps = () => axios.get("item_type/all");

// get all brand in step1
export const allBrands = () => axios.post("brand/all");

// get the detailed items with model id
export const allItemsByModelId = (model_id, package_id, promocode) =>
	axios.post("item/all", { model_id, package_id, promocode });

// get the detailed items with model id and promocode
export const allItemsByModelIdAndPCode = (
	brand_id,
	brandName,
	model_id,
	manuYear,
	promocode,
	package_id
) =>
	axios.post("item/all", {
		brand_id,
		brand: brandName,
		model: model_id,
		model_id,
		year: manuYear,
		promocode,
		package_id,
	});

// check promocode
export const checkPCode = (promocode, controller = AbortSignal.timeout(5000)) =>
	axios.get(`/promocode/check`, {
		params: { promocode },
		signal: controller.signal,
	});

// calculate the total price
export const calcTotal = (params) => axios.post("/order/calcTotal", params);

// get the order quota2
export const orderQuota2 = (params) => axios.post("/order/quota2", params);

// get the order quota2
export const createOrder = (params) => axios.post("/order/create", params);

export const allBrandsFromAIRTable = () => {
	const data = mrWrapperModelList.getAllRecordsWithLimit().then((resp) => {
		const grouped = resp.reduce((acc, record) => {
			// If this Make_ID hasn't been processed yet, initialize it
			if (!acc[record.fields.Make_ID]) {
				acc[record.fields.Make_ID] = {
					id: record.fields.Make_ID,
					title_enHK: record.fields.Make.split(" ")[0],
					title_zhHK: record.fields.Make.split(" ")[1] || "",
					deleted: 0,
					models: [],
					airtableID: record.id,
				};
			}

			// Add the model to the appropriate make
			acc[record.fields.Make_ID].models.push({
				id: record.fields.ID,
				typerate_id: record.fields["Type Rate"][0],
				brand_id: record.fields.Make_ID,
				model_key: record.fields["Model Key"].toString(),
				title: record.fields.Model,
				deleted: 0,
				airtableID: record.id,
			});

			return acc;
		}, {});

		// Convert from object to array and sort by ID
		return Object.values(grouped).sort((a, b) => a.id - b.id);
	});

	return data;
};

export const calculateBookingTime = (params) => {
	return new Promise((res, rej) => {
		res({
			data: generateDateRange({ ...params }),
			status: 0,
		});
	});
};

const generateDateRange = (data) => {
	// Get all start and end dates
	const startDate = new Date(data.start_time);
	const endDate = new Date(data.end_time);

	const ranges = [];
	const currentDate = new Date(startDate);

	// Set initial time to 10:00
	currentDate.setHours(10, 0, 0);

	while (currentDate < endDate) {
		// Only create ranges for hours between 10:00 and 18:00
		if (currentDate.getHours() >= 10 && currentDate.getHours() <= 18) {
			// Create end date that's 5 days after current date
			const rangeEndDate = new Date(currentDate);
			rangeEndDate.setDate(rangeEndDate.getDate() + 5);

			ranges.push({
				start_time: formatDate(currentDate),
				end_time: formatDate(rangeEndDate),
				position: 0,
				quota: 2,
				require_hours: 45,
				total_hours: 120,
			});

			// Move to next hour
			currentDate.setHours(currentDate.getHours() + 1);
		} else {
			// If outside business hours, move to next day at 10:00
			currentDate.setDate(currentDate.getDate() + 1);
			currentDate.setHours(10, 0, 0);
		}
	}

	return ranges;
};

const formatDate = (date) => {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const day = String(date.getDate()).padStart(2, "0");
	const hours = String(date.getHours()).padStart(2, "0");
	const minutes = String(date.getMinutes()).padStart(2, "0");

	return `${year}-${month}-${day} ${hours}:${minutes}`;
};
