const AIRTABLE_TOKEN_KEY =
	"patxolmYIKYkoClWc.3f24cfc872ebaf24a7f80698147a9db37ec53af0e5f06f7825457261d21249a3";

const AIRTABLE_ID = "appy5hYKsORrKNu04";
const AIRTABLE_NAME_MR_WRAPPER_MODEL_LIST = "tblApKojYDoe06r4A";

// https://airtable.com/appy5hYKsORrKNu04/tblApKojYDoe06r4A/viwtpl3oyx8ocZ22k?blocks=hide
class AirtableService {
	constructor(apiKey, baseId, tableName) {
		this.apiKey = apiKey;
		this.baseId = baseId;
		this.tableName = tableName;
		this.baseURL = `https://api.airtable.com/v0/${this.baseId}/${this.tableName}`;
	}

	// Get headers for API calls
	get headers() {
		return {
			Authorization: `Bearer ${this.apiKey}`,
			"Content-Type": "application/json",
		};
	}

	// Get all records
	async getAllRecords() {
		try {
			const response = await fetch(this.baseURL, {
				method: "GET",
				headers: this.headers,
			});

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const data = await response.json();
			return data.records;
		} catch (error) {
			console.error("Error fetching records:", error);
			throw error;
		}
	}

	async getAllRecordsWithLimit() {
		let allRecords = [];
		let offset = null;

		do {
			const url = `https://api.airtable.com/v0/${this.baseId}/${
				this.tableName
			}${offset ? `?offset=${offset}` : ""}`;
			const response = await fetch(url, {
				headers: this.headers,
			});
			const data = await response.json();
			allRecords = [...allRecords, ...data.records];
			offset = data.offset;
		} while (offset);

		return allRecords;
	}

	// Get a single record by ID
	async getRecord(recordId) {
		try {
			const response = await fetch(`${this.baseURL}/${recordId}`, {
				method: "GET",
				headers: this.headers,
			});

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			return await response.json();
		} catch (error) {
			console.error("Error fetching record:", error);
			throw error;
		}
	}

	// Create a new record
	async createRecord(fields) {
		try {
			const response = await fetch(this.baseURL, {
				method: "POST",
				headers: this.headers,
				body: JSON.stringify({ fields: fields }),
			});

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			return await response.json();
		} catch (error) {
			console.error("Error creating record:", error);
			throw error;
		}
	}

	// Update a record
	async updateRecord(recordId, fields) {
		try {
			const response = await fetch(`${this.baseURL}/${recordId}`, {
				method: "PATCH",
				headers: this.headers,
				body: JSON.stringify({ fields }),
			});

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			return await response.json();
		} catch (error) {
			console.error("Error updating record:", error);
			throw error;
		}
	}

	// Delete a record
	async deleteRecord(recordId) {
		try {
			const response = await fetch(`${this.baseURL}/${recordId}`, {
				method: "DELETE",
				headers: this.headers,
			});

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			return await response.json();
		} catch (error) {
			console.error("Error deleting record:", error);
			throw error;
		}
	}

	// Query records with formula
	async queryRecords(filterFormula) {
		try {
			const params = new URLSearchParams({
				filterByFormula: filterFormula,
			});

			const response = await fetch(`${this.baseURL}?${params}`, {
				method: "GET",
				headers: this.headers,
			});

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const data = await response.json();
			return data.records;
		} catch (error) {
			console.error("Error querying records:", error);
			throw error;
		}
	}
}

export const mrWrapperModelList = new AirtableService(
	AIRTABLE_TOKEN_KEY,
	AIRTABLE_ID,
	AIRTABLE_NAME_MR_WRAPPER_MODEL_LIST
);

const AIRTABLE_TOKEN_KEY_ORDER =
	"patqNBrkFFP60ltGm.060cd3eb5c40c28527a004895a00f94630df28b83372d320dc6334a378536653";

const AIRTABLE_ID_ORDER = "appB9VowjFinOeA4S";
const AIRTABLE_NAME_ORDER = "Mr Wrapper Online Orders";

export const orderAirtable = new AirtableService(
	AIRTABLE_TOKEN_KEY_ORDER,
	AIRTABLE_ID_ORDER,
	AIRTABLE_NAME_ORDER
);
