import { memo, Suspense, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { isEmpty, isEqual } from "lodash";
import LazyLoad from "react-lazyload";
import MySpinner from "components/common/MySpinner";
import PopupAD from "components/common/PopupAD";
import PopupADBanner from "components/common/PopupADBanner";
import Toast from "components/common/Toast";
import {
	LazyBanner,
	LazyCommentPage,
	LazyInstantQuote,
	LazyNgoPlan,
	LazyProductDescription,
	LazyTeamAndBooking,
} from "config/lazyComponent";
import { popupImages } from "config/defaultData";
import { getCookies, setCookies } from "utils/func";
import "./index.css";
import {
	Key_cookie_promocode,
	PickupInfo,
	TipsInfo,
	Value_promocode,
} from "config/text";
import Booking from "./TeamAndBooking/Booking";

const PopupImages = popupImages;

/**
 * lazy load images and component
 */
function Body() {
	// state relating to popup ad
	const [ad, setAD] = useState(true);
	const [adBanner, setADBanner] = useState(true);
	const [adToast, setADToast] = useState(false);
	const [isPickup, setIsPickup] = useState(false);
	const { search } = useLocation();

	// handler relating to popup ad
	const hideAD = useCallback(() => setAD(false), []);
	const hideADBanner = useCallback(() => setADBanner(false), []);
	const hideADToast = useCallback(() => setADToast(false), []);
	const showADToast = useCallback(() => setADToast(true), []);

	useEffect(() => {
		// hide popup ad when existing dc query string in URL
		if (!isEmpty(search)) {
			const queryString = search.slice(1).split("&");
			queryString.map((i) => {
				const [key, value] = i.split("=");
				if (isEqual(key, Key_cookie_promocode)) {
					hideAD();
					hideADBanner();
					hideADToast();
					setCookies(value);
				}
			});
		}

		const cookieData = getCookies();
		if (isEqual(cookieData.status, 1)) {
			hideAD();
			hideADToast();
			setIsPickup(true);
		}
	}, []);

	return (
		<>
			<div id="top"></div>

			{/* {ad && <PopupAD images={PopupImages} closeBtnOnClick={hideAD} />} */}
			{/* {adBanner && <PopupADBanner closeBtnOnClick={hideADBanner} hideAD={hideAD} showADToast={showADToast} setIsPickup={setIsPickup} isPickup={isPickup} />} */}
			{adToast && (
				<Toast
					hideADToast={hideADToast}
					pickUpInfo={PickupInfo}
					tipsInfo={TipsInfo}
					promocode={Value_promocode}
				/>
			)}

			<div className="body">
				<Suspense fallback={<MySpinner />}>
					<LazyBanner />
				</Suspense>

				<LazyLoad height={680} once offset={100}>
					<Suspense fallback={<MySpinner />}>
						<LazyInstantQuote />
					</Suspense>
				</LazyLoad>

				<LazyLoad height={1500} once offset={100}>
					<Suspense fallback={<MySpinner />}>
						<LazyProductDescription />
					</Suspense>
				</LazyLoad>

				<Booking />

				<LazyLoad height={550} once offset={100}>
					<Suspense fallback={<MySpinner />}>
						<LazyCommentPage />
					</Suspense>
				</LazyLoad>

				{/* <LazyLoad height={1150} once offset={100} >
          <Suspense fallback={<MySpinner />}>
            <LazyNgoPlan />
          </Suspense>
        </LazyLoad> */}

				<LazyLoad height={1100} once offset={100}>
					<Suspense fallback={<MySpinner />}>
						<LazyTeamAndBooking />
					</Suspense>
				</LazyLoad>
			</div>
		</>
	);
}

export default memo(Body);
